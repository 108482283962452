import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../../../layouts/Layout';
import useCheckArchType from '../../../hooks/useCheckArchType';
import Form from '../../../components/Form/Form';
import MillerDisplay from '../../../components/Typography/MillerDisplay/MillerDisplay';
import Wysiwyg from '../../../components/Wysiwyg/Wysiwyg';

import * as containStyles from '../../../styles/contain.module.scss';

import Space from '../../../components/Space/Space';

interface Props {
    data: {
      archetype: ArcheType;
    };
}

const ArchTypeResultPage = ({ data: { archetype } }: Props): JSX.Element => {
    /**
       * To ensure people only hit this page if they have answered.
       * if archetype isnt in storage then we'll redirect back to the homepage.
    */
    const [archType] = useCheckArchType(() => navigate('/'));

    const onFormSubmitSuccess = () => { navigate(`/quiz/result/${archetype.slug}/thank-you`); };


    if (!archType) return <p>Loading...</p>;

    return (
        <Layout>
            <section className={`${containStyles.contain} ${containStyles.containMedium}`}>
                <div className="grid md:grid-cols-2 gap-8">
                    <div className="order-2 md:order-1">
                        <Space spacing={4}>
                            <MillerDisplay tag="h1" typeset="subtitle" className="hidden md:block">{archetype.title}</MillerDisplay>
                            <Wysiwyg dangerouslySetInnerHTML={{ __html: archetype.contentOne }} />
                            <Form aB="B" onSuccess={onFormSubmitSuccess} formNumber={4079} />
                        </Space>
                    </div>
                    <div className="order-1 md:order-2 space-y-8">
                        <img src={archetype.imagePath} alt={archetype.name} />
                        <Space spacing={2}>
                            <MillerDisplay tag="h2" typeset="title" className="md:hidden">{archetype.title}</MillerDisplay>
                            <MillerDisplay tag="h2" typeset="subtitle" className="hidden md:block">{archetype.title}</MillerDisplay>
                            <Wysiwyg dangerouslySetInnerHTML={{ __html: archetype.contentTwo }} />
                        </Space>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default ArchTypeResultPage;

export const query = graphql`
  query($id: String) {
    archetype(id: { eq: $id }) {
      name
      title
      slug
      imagePath
      contentOne
      contentTwo
    }
  }
`;
